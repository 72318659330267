/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */
import * as _ from "lodash";

import { failed, inProgress, success } from "utils";

export function loadLocation(uidOrSlug) {
  return async function* call(firebase) {
    yield { type: "LOAD_LOCATION_STATE", value: inProgress() };
    try {
      let ref = firebase.database().ref("locations");
      if (uidOrSlug.startsWith("-")) {
        ref = ref.child(uidOrSlug);
      } else {
        ref = ref.orderByChild("slug").equalTo(uidOrSlug);
      }
      const $location = await ref.get();
      if (!$location.exists()) {
        yield { type: "LOAD_LOCATION_STATE", value: success(null) };
        return;
      }

      let location = $location.val();
      if ($location.key === "locations") {
        const uid = _.keys(location)[0];
        location = location[uid];
        location.uid = uid;
      } else {
        location.uid = uidOrSlug;
      }

      // review stats
      try {
        const args = { location: location.uid };
        const { data } = await firebase.functions().httpsCallable("getBasicReviewStats")(args);
        location.totalReviewCount = data.totalReviewCount;
      } catch (e) {
        console.error("failed to load location review stats", e);
      }

      yield { type: "LOAD_LOCATION_STATE", value: success(location) };
    } catch (error) {
      yield { type: "LOAD_LOCATION_STATE", value: failed(error) };
    }
  };
}

export function submitNegativeReview(uidOrSlug, data) {
  return async function* call(firebase) {
    yield { type: "SUBMIT_NEGATIVE_REVIEW_STATE", value: inProgress() };
    try {
      const args = _.merge({ location: uidOrSlug }, data);
      const res = await firebase.functions().httpsCallable("submitNegativeReview")(args);
      yield {
        type: "SUBMIT_NEGATIVE_REVIEW_STATE",
        value: success(res.data)
      };
    } catch (error) {
      yield { type: "SUBMIT_NEGATIVE_REVIEW_STATE", value: failed(error) };
    }
  };
}

export function SubmitFeedbackRatingAction(uidOrSlug, rating) {
  return async function* (firebase) {
    yield { type: "PRESUBMIT_RATING", value: inProgress() };
    try {
      const args = { location: uidOrSlug, rating };
      const res = await firebase.functions().httpsCallable("submitFeedbackRating")(args);
      yield {
        type: "PRESUBMIT_RATING",
        value: success(res.data)
      };
    } catch (error) {
      yield { type: "PRESUBMIT_RATING", value: failed(error) };
    }
  };
}
