/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */
import _ from "lodash";
import { useCallback, useContext, useEffect, useReducer as useReactReducer, useState } from "react";

import { AppContext } from "containers";

const cache = {};

export const useReducer = (reducer, initialState, volatileState = {}, cacheKey) => {
  const [mounted, setIsMounted] = useState(true);
  const [state, _dispatch] = useReactReducer(reducer, {}, () => {
    return _.merge({},
      _.omit(cacheKey ? _.get(cache, cacheKey, initialState) : initialState, _.keys(volatileState)),
      volatileState);
  });
  const { firebase } = useContext(AppContext);
  useEffect(() => {
    if (cacheKey) _.set(cache, cacheKey, state);
  }, [state]);
  useEffect(() => () => setIsMounted(false), []);
  const dispatch = useCallback(async (action) => {
    if (action.constructor.name.endsWith("Function")) {
      const gen = action(firebase);
      for await (let update of gen) {
        if (!mounted) {
          console.warn("cannot emit async action update, component unmounted");
          if (cacheKey) {
            _.set(cache, cacheKey, reducer(_.get(cache, cacheKey), update));
          }
        } else {
          _dispatch(update);
        }
      }
    } else {
      _dispatch(action);
    }
  }, [reducer]);
  return [state, dispatch];
};