/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */

import styles from "./styles.module.css";

import React from "react";
import PropTypes from "prop-types";
import { Form as BtForm } from "react-bootstrap";

import Error from "../Error";

const Form = ({ className, children, onSubmit, error }) => {
  const classes = [styles.form, className];
  return (
    <div className={classes.join(" ")}>
      <Error className={styles.error}>{error}</Error>
      <BtForm onSubmit={onSubmit}>
        {children}
      </BtForm>
    </div>
  );
};

Form.defaultProps = {
};
Form.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  onSubmit: PropTypes.func,
  error: PropTypes.string,
};

export default Form;