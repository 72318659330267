/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */

import styles from "./styles.module.css";

import React from "react";
import PropTypes from "prop-types";

const BackPrint = ({ className, children, height, ...rest }) => {
  const classes = [styles.backPrint, className];
  return (
    <div className={classes.join(" ")} style={{ height }} {...rest}>
      {children}
    </div>
  );
};

BackPrint.defaultProps = {
  height: "300px",
};
BackPrint.propTypes = {
  height: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default BackPrint;