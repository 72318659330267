/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */

import _ from "lodash";
import * as yup from "yup";

import PropTypes from "prop-types";
import { useCallback, useMemo, useState } from "react";

import { Form, InputField } from "components/forms";

import { Button } from "components/buttons";
import styles from "./styles.module.css";

const formSchema = yup.object().shape({
  name: yup.string().required("Required"),
  email: yup.string().email("Invalid Email").required("Required"),
});

const FormBloc = ({ className, location, onNext, ...rest }) => {
  const defaultEmail = useMemo(() => {
    const params = new URLSearchParams(window.location.search);
    return params.get("email");
  }, []);

  const [formData, setFormData] = useState({
    name: "",
    email: defaultEmail || "",
  });
  const [formErrors, setFormErrors] = useState({});

  const validateBeforeGoNext = useCallback((data) => {
    try {
      formSchema.validateSync(data, { abortEarly: false });
    } catch (error) {
      const newErrors = _.clone(formErrors);
      error.inner.forEach(e => newErrors[e.path] = e.message);
      setFormErrors(newErrors);
      return;
    }
    return onNext(data);
  }, []);

  const onChange = useCallback(({ target: { name, value } }) => {
    if (_.has(formErrors, name)) {
      const newErrors = _.clone(formErrors);
      delete newErrors[name];
      setFormErrors(newErrors);
    }
    const data = _.cloneDeep(formData);
    _.set(data, name, value);
    setFormData(data);
  }, [formData]);

  return (
    <div className={[styles.container, className].join(" ")} {...rest}>
      <p className={styles.title}>We’d Love to hear from you.</p>
      <p className={styles.subtitle}>
        Your feedback is crucial in shaping our future health programs.
        How would your rate your experience with {location.name}?
      </p>
      <svg width="100%" height="1" viewBox="0 0 600 1" fill="none" xmlns="http://www.w3.org/2000/svg">
        <line y1="0.5" x2="600" y2="0.5" stroke="#DEE2E6" strokeDasharray="4 4" />
      </svg>
      <Form className={styles.form}>
        <div className={styles.formFields}>
          <InputField
            className={styles.formInputField}
            name="name"
            label="Full Name"
            placeholder="Write your name..."
            value={formData.name}
            error={formErrors.name}
            onChange={onChange}
          />
          <InputField
            className={styles.formInputField}
            name="email"
            label="Email Address"
            placeholder="Write your email..."
            value={formData.email}
            error={formErrors.email}
            onChange={onChange}
          />
        </div>
        <Button
          className={styles.formButton}
          text="I agree, let's get started."
          onClick={() => {
            validateBeforeGoNext(formData);
          }}
        />
      </Form>
    </div >
  );
};

FormBloc.defaultProps = {
};
FormBloc.propTypes = {
  className: PropTypes.string,
  location: PropTypes.object.isRequired,
  onNext: PropTypes.func.isRequired,
};

export default FormBloc;