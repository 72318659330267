/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */

import styles from "./styles.module.css";

import React from "react";
import PropTypes from "prop-types";

const FormSubtitle = ({ text, className }) => (
  <h1 className={[styles.subtitle, className].join(" ")}>{text}</h1>
);

FormSubtitle.defaultProps = {
};
FormSubtitle.propTypes = {
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default FormSubtitle;