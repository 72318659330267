/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */

import styles from "./styles.module.css";

import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import amplitude from "amplitude-js";

import { ReactComponent as Icon } from "./icon.svg";
import { Button } from "components/buttons";

const GoodRatingPopup = ({ location, onClose, ...rest }) => {
  let reviewLink;
  const links = [];
  if (!_.isEmpty(_.get(location, "googleReviewLink.url")) &&
    _.get(location, "googleReviewLink.enabled", false))
    links.push(location.googleReviewLink.url);
  if (!_.isEmpty(_.get(location, "yelpReviewLink.url")) &&
    _.get(location, "yelpReviewLink.enabled", false))
    links.push(location.yelpReviewLink.url);
  if (links.length > 0) {
    const max = links.length - 1;
    const idx = Math.round((Math.random() * max));
    reviewLink = links[idx];
  }

  return (
    <div className={styles.container} {...rest}>
      <div className={styles.icon}>
        <Icon width={120} height={120} />
      </div>
      <p className={styles.title}>
        Wow! We&apos;re so glad you had a great experience with us!
      </p>
      <p className={styles.text}>
        We&apos;re now sending you to an external review site to leave us a public review. Thank you so much!
      </p>
      <Button
        className={styles.button}
        text="Ok, Let's Go!"
        onClick={() => {
          amplitude.getInstance().logEvent("OkayLetUsGoClick", {
            location: location.name,
            reviewLink,
          });
          if (reviewLink != null)
            window.open(reviewLink, "_self");
        }}
      />
      <a href="#"
        onClick={() => {
          amplitude.getInstance().logEvent("NoTrankYouPublicReviewClick", {
            location: location.name,
          });
          onClose();
        }}>
        No, thank you, I don&apos;t want to leave a public review
      </a>
    </div>
  );
};

GoodRatingPopup.defaultProps = {
};
GoodRatingPopup.propTypes = {
  location: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default GoodRatingPopup;