/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */
import * as _ from "lodash";

import { failed, inProgress, success } from "utils";


export function LoadVideoCampaignAction(uid) {
  return async function* call(firebase) {
    yield { type: "LOAD_VIDEO_CAMPAIGN_STATE", value: inProgress() };
    try {
      let ref = firebase.database().ref("video_campaigns").child(uid);
      const $campaign = await ref.get();
      if (!$campaign.exists()) {
        yield { type: "LOAD_VIDEO_CAMPAIGN_STATE", value: success(null) };
        return;
      }

      let campaign = $campaign.val();
      campaign.uid = uid;
      yield { type: "LOAD_VIDEO_CAMPAIGN_STATE", value: success(campaign) };
    } catch (error) {
      yield { type: "LOAD_VIDEO_CAMPAIGN_STATE", value: failed(error) };
    }
  };
}

export function LoadLocationAction(uid) {
  return async function* call(firebase) {
    yield { type: "LOAD_LOCATION_STATE", value: inProgress() };
    try {
      let ref = firebase.database().ref("locations").child(uid);
      const $location = await ref.get();
      if (!$location.exists()) {
        yield { type: "LOAD_LOCATION_STATE", value: success(null) };
        return;
      }

      let location = $location.val();
      if ($location.key === "locations") {
        const uid = _.keys(location)[0];
        location = location[uid];
        location.uid = uid;
      } else {
        location.uid = uid;
      }
      yield { type: "LOAD_LOCATION_STATE", value: success(location) };
    } catch (error) {
      yield { type: "LOAD_LOCATION_STATE", value: failed(error) };
    }
  };
}

export function SubmitVideoReview(campaign, userData, records) {
  return async function* (firebase) {
    yield { type: "SUBMIT_VIDEO_REVIEW", value: inProgress() };
    try {
      const args = { campaign, user: userData, records };
      const res = await firebase.functions().httpsCallable("submitVideoCampaignReply")(args);
      yield {
        type: "SUBMIT_VIDEO_REVIEW",
        value: success(res.data)
      };
    } catch (error) {
      yield { type: "SUBMIT_VIDEO_REVIEW", value: failed(error) };
    }
  };
}