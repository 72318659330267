/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */
import moment from "moment";
import PropTypes from "prop-types";

import { ApiVideoMediaRecorder } from "@api.video/media-recorder";
import { useEffect, useMemo, useState } from "react";

import { BackPrint } from "components/back";
import { Button } from "components/buttons";

import styles from "./styles.module.css";


const RecodingBloc = ({ className, campaign, onCompleted, isSubmitting, ...rest }) => {
  const [stream, setStream] = useState(null);
  useEffect(() => {
    if (navigator.mediaDevices) {
      var constraints = window.constraints = {
        audio: true,
        video: true
      };
      navigator.mediaDevices.getUserMedia(constraints).then((s) => {
        setStream(s);
      });
    } else {
      setStream(undefined);
    }
  }, []);

  const [isRecording, setIsRecording] = useState(null);
  const [recordingTime, setRecordingTime] = useState();
  const [isSavingVideo, setIsSavingVideo] = useState(false);
  const [records, setRecords] = useState([]);
  const recorder = useMemo(() => {
    if (stream != null) {
      const recorder = new ApiVideoMediaRecorder(stream, {
        uploadToken: campaign.uploadToken.token,
      });
      recorder.addEventListener("recordingStopped", () => {
        setIsRecording(null);

        if (records.length + 1 < campaign.questions.length) {
          setTimeout(() => {
            const nextEl = document.getElementById(`question_${records.length + 1}`);
            nextEl.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
          }, 2000);
        }
      });
      const videoEl = document.querySelector("#video");
      videoEl.srcObject = stream;
      videoEl.play();
      return recorder;
    }
    return null;
  }, [stream]);

  useEffect(() => {
    if (isRecording !== null) {
      if (!recordingTime) {
        let time = moment("00:00:00", "HH:mm:ss");
        setRecordingTime(time);
      }
      const timer = setTimeout(() => {
        const newTime = moment(recordingTime).add(1, "s");
        setRecordingTime(newTime);
      }, 1_000);
      return () => clearInterval(timer);
    } else if (recordingTime) {
      setRecordingTime(null);
    }
  }, [isRecording, recordingTime]);

  useEffect(() => {
    setTimeout(() => {
      if (recorder != null) {
        const hintEl = document.getElementById("hint");
        if (hintEl)
          hintEl.style.opacity = 0;
      }
    }, 2500);
  }, [recorder == null]);

  if (stream === undefined) {
    return (
      <BackPrint>
        <p className="title">Video Error</p>
        <p className="message">Looks like this device does not support recording video</p>
      </BackPrint>
    );
  }

  return (
    <div className={[styles.container, className].join(" ")} {...rest}>
      <div className={styles.record}>
        <div id="videoFrame" className={styles.videoFrame}>
          <video id="video" playsInline={true}></video>
          <div className="frame-left" />
          <div className="frame-top" />
          <div className="frame-right" />
          <div className="frame-bottom">
            <div id="hint" className="hint">
              <svg width="40" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_1913_29622)">
                  <path d="M16.6667 1.66699H3.34172C2.42505 1.66699 1.67505 2.41699 1.67505 3.33366V18.3337L5.00005 15.0003H16.6667C17.5834 15.0003 18.3334 14.2503 18.3334 13.3337V3.33366C18.3334 2.41699 17.5834 1.66699 16.6667 1.66699ZM10.8334 11.667H9.16672V10.0003H10.8334V11.667ZM10.8334 7.50033C10.8334 7.95866 10.4584 8.33366 10 8.33366C9.54172 8.33366 9.16672 7.95866 9.16672 7.50033V5.83366C9.16672 5.37533 9.54172 5.00033 10 5.00033C10.4584 5.00033 10.8334 5.37533 10.8334 5.83366V7.50033Z" fill="#F79009" />
                </g>
                <defs>
                  <clipPath id="clip0_1913_29622">
                    <rect width="20" height="20" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              Please keep your face in the frame for better video quality
            </div>
          </div>
        </div>
        {isRecording !== null ? (
          <>
            <span className={styles.recordingTime}>{moment(recordingTime).format("HH:mm:ss")}</span>
            <Button
              className={styles.recordButton}
              icon={
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8 6H16C17.1 6 18 6.9 18 8V16C18 17.1 17.1 18 16 18H8C6.9 18 6 17.1 6 16V8C6 6.9 6.9 6 8 6Z" fill="white" />
                </svg>
              }
              text="Stop recording"
              loading={isSavingVideo}
              onClick={() => {
                setIsSavingVideo(true);
                recorder.stop()
                  .then(video => {
                    console.log(video);
                    setIsSavingVideo(false);
                    const newRecords = records.slice();
                    newRecords[isRecording] = video;
                    setRecords(newRecords);
                  }).catch(error => {
                    console.error(error);
                    setIsSavingVideo(false);
                  });
              }}
            />
          </>
        ) : records.length === campaign.questions.length ? (
          <Button
            className={styles.recordButton}
            loading={isSubmitting}
            text="Send Video Feedback"
            onClick={() => {
              onCompleted(records);
            }}
          />
        ) : (
          <Button
            className={styles.recordButton}
            icon={
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17 10.5V7C17 6.45 16.55 6 16 6H4C3.45 6 3 6.45 3 7V17C3 17.55 3.45 18 4 18H16C16.55 18 17 17.55 17 17V13.5L19.29 15.79C19.92 16.42 21 15.97 21 15.08V8.91C21 8.02 19.92 7.57 19.29 8.2L17 10.5Z" fill="white" />
              </svg>
            }
            text={`Begin recording for Question ${records.length + 1}`}
            disabled={recorder == null}
            onClick={() => {
              recorder.start();
              setIsRecording(records.length);
            }}
          />
        )}
      </div>
      <div id="question_frame" className={styles.form}>
        <h3>Video feedback for Dr. Eman Elmi, DPM</h3>
        <span>{campaign.questions.length} Questions</span>
        {campaign.questions.map((question, index) => (
          <div key={index} id={`question_${index}`} >
            <svg width="100%" height="1" viewBox="0 0 475 1" fill="none" xmlns="http://www.w3.org/2000/svg">
              <line y1="0.5" x2="475" y2="0.5" stroke="#DEE2E6" strokeDasharray="4 4" />
            </svg>
            <p>Question {index + 1}</p>
            <code>{question}</code>
            <div>
              {records[index] ? (
                <>
                  <img
                    src={records[index].assets.thumbnail}
                    onError={({ currentTarget }) => {
                      // currentTarget.onerror = null;
                      currentTarget.src = "/images/loading_image.png";
                      setTimeout(() => {
                        currentTarget.src = records[index].assets.thumbnail;
                      }, 1000);
                    }}
                  />
                  <div className={styles.recordButtons}>
                    <Button
                      className={styles.outlineButton}
                      text="Retake video"
                      disabled={isRecording != null}
                      onClick={() => {
                        setIsRecording(index);
                        recorder.start();
                        const newRecords = records.slice();
                        newRecords[index] = null;
                        setRecords(newRecords);
                      }}
                    />
                    <Button
                      className={styles.outlineButton}
                      text="Preview"
                      tail={
                        <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M4.00014 0.999674C4.00014 1.37301 4.30014 1.66634 4.66681 1.66634H8.39348L1.13348 8.92634C0.873477 9.18634 0.873477 9.60634 1.13348 9.86634C1.39348 10.1263 1.81348 10.1263 2.07348 9.86634L9.33348 2.60634V6.33301C9.33348 6.69967 9.63348 6.99967 10.0001 6.99967C10.3668 6.99967 10.6668 6.69967 10.6668 6.33301V0.999674C10.6668 0.633008 10.3668 0.333008 10.0001 0.333008H4.66681C4.30014 0.333008 4.00014 0.633008 4.00014 0.999674Z" fill="#2A62FE" />
                        </svg>
                      }
                      onClick={() => {
                        window.open(records[index].assets.player, "_blank");
                      }}
                    />
                  </div>
                </>
              ) : isRecording === index ? "Recoding..." : "No video"}
            </div>
          </div>
        ))}
      </div>
    </div >
  );
};

RecodingBloc.defaultProps = {
};
RecodingBloc.propTypes = {
  className: PropTypes.string,
  campaign: PropTypes.object.isRequired,
  onCompleted: PropTypes.object.isRequired,
  isSubmitting: PropTypes.bool,
};

export default RecodingBloc;