/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */

import { Bloc } from "components/panels";
import { ReactComponent as Image } from "./image.svg";

import styles from "./styles.module.css";

const ThanksBloc = ({ ...rest }) => {
  return (
    <Bloc className={styles.container} {...rest}>
      <div className={styles.image}>
        <Image />
      </div>
      <p className={styles.title}>Thank You for Your Feedback!</p>
      <p className={styles.text}>Your feedback is invaluable to us as we strive to improve and make a positive impact on your health journey. Thank you for being a part of our mission for a healthier tomorrow!</p>
    </Bloc>
  );
};

ThanksBloc.defaultProps = {
};
ThanksBloc.propTypes = {
};

export default ThanksBloc;