/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */
import styles from "./styles.module.css";

import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import * as yup from "yup";
import amplitude from "amplitude-js";

import { ReactComponent as Icon } from "./icon.svg";
import { ReviewStarIcon } from "components/icons";
import { Button } from "components/buttons";
import { Form, InputField } from "components/forms";
import PoweredBy from "../PoweredBy";

const formSchema = yup.object().shape({
  name: yup.string().required("Required"),
  email: yup.string().email("Invalid Email").required("Required"),
  comment: yup.string().min(10, "Too short").required("Required"),
});

const FeedbackBloc = ({ className, location, isSubmitting, onSubmit, onRatingSelected, ...rest }) => {
  // state
  const [formData, setFormData] = useState({
    rating: 0,
    name: "",
    email: "",
    comment: "",
    improvetags: [],
  });
  const [formErrors, setFormErrors] = useState({});

  // callbacks
  const validateBeforeSubmit = useCallback((data) => {
    if (data.rating < 4) {
      try {
        formSchema.validateSync(data, { abortEarly: false });
      } catch (error) {
        const newErrors = _.clone(formErrors);
        error.inner.forEach(e => newErrors[e.path] = e.message);
        setFormErrors(newErrors);
        return;
      }
    }
    onSubmit(data);
  }, []);

  const onChange = useCallback(({ target: { name, value } }) => {
    if (_.has(formErrors, name)) {
      const newErrors = _.clone(formErrors);
      delete newErrors[name];
      setFormErrors(newErrors);
    }
    const data = _.cloneDeep(formData);
    _.set(data, name, value);
    setFormData(data);
  }, [formData]);

  return (
    <div className={[styles.container, className].join(" ")} {...rest}>
      <p className={styles.title}>Feedback</p>
      <div className={styles.icon}>
        {location.image ? (
          <img src={location.image} />
        ) : (
          <Icon height={120} width={120} />
        )}
      </div>
      <p className={styles.subtitle}>
        Please rate your experience with
        <br />{location.name}
      </p>
      <div className={styles.stars}>
        {_.range(0, 5).map(i => <ReviewStarIcon
          key={`star_${i}`}
          fill={i < formData.rating}
          onClick={() => {
            amplitude.getInstance().logEvent("StarClick", {
              location: location.name,
              rating: i + 1,
            });
            if (formData.rating !== i + 1) {
              setFormData({ ...formData, rating: i + 1 });
              onRatingSelected({ ...formData, rating: i + 1 });
            }
          }}
        />)}
      </div>
      {location.totalReviewCount && (
        <p className="totalReviewCount">{location.totalReviewCount} patients have recently rated {location.name}</p>
      )}
      <Devider />
      {formData.rating > 0 && formData.rating < 4 &&
        <>
          {_.isEmpty(location.improvetags)
            ? <h1 className={styles.noimprovetags_title}>How can we do better?</h1>
            : <>
              <h1 className={styles.improvetags_title}>Tell us what can be improved</h1>
              <div className={styles.improvetags}>
                {
                  location.improvetags.map((tag, index) =>
                    <div key={`improvetag_${index}`}
                      data-selected={formData.improvetags.includes(tag)}
                      onClick={() => {
                        amplitude.getInstance().logEvent("ImprovmentClick", {
                          location: location.name,
                          improvement: tag,
                          selected: !formData.improvetags.includes(tag),
                        });
                        setFormData({
                          ...formData,
                          improvetags: formData.improvetags.includes(tag)
                            ? formData.improvetags.filter(t => t !== tag)
                            : [...formData.improvetags, tag],
                        });
                      }}>{tag}</div>)
                }
              </div>
              <Devider />
            </>
          }
          <Form className={styles.form}>
            <InputField
              name="name"
              label="Your Name"
              placeholder="Write your name..."
              value={formData.name}
              error={formErrors.name}
              onChange={onChange}
              disabled={isSubmitting}
            />
            <InputField
              name="email"
              label="Your Email"
              placeholder="Write your email..."
              value={formData.email}
              error={formErrors.email}
              onChange={onChange}
              disabled={isSubmitting}
            />
            <InputField
              as="textarea"
              rows={3}
              name='comment'
              label="Tell us about your experience"
              value={formData.comment}
              maxLength={500}
              error={formErrors.comment}
              onChange={onChange}
              disabled={isSubmitting}
            />
          </Form>
        </>
      }
      <div className={styles.buttons}>
        <Button
          text="Submit Feedback"
          disabled={formData.rating < 1}
          loading={isSubmitting}
          onClick={() => {
            amplitude.getInstance().logEvent("SubmitClick", {
              location: location.name,
              ...formData,
            });
            validateBeforeSubmit(formData);
          }}
        />
        <PoweredBy locationName={location.name} />
      </div>
    </div >
  );
};

const Devider = () => {
  return <svg className={styles.devider} viewBox="0 0 800 2" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 1H800" stroke="#E7E7E7" strokeDasharray="10 7" />
  </svg>;
};

FeedbackBloc.defaultProps = {
  isSubmitting: false,
};
FeedbackBloc.propTypes = {
  className: PropTypes.string,
  location: PropTypes.object.isRequired,
  isSubmitting: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  onRatingSelected: PropTypes.func.isRequired,
};

export default FeedbackBloc;