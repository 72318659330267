/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */
import styles from "./styles.module.css";
import firebaseConfig from "firebase.config";

import firebase from "firebase";
import amplitude from "amplitude-js";
import React, { createContext } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import { BackPrint } from "components/back";

import HomeContainer from "./home";
import VideoContainer from "./video";
import { useReducer } from "./reducer";

const isDebugMode = process.env.NODE_ENV === "development";
if (isDebugMode) {
  firebaseConfig.databaseURL = "https://review-x-dev-db.europe-west1.firebasedatabase.app/";
}
const firebaseApp = firebase.initializeApp(firebaseConfig);
if (isDebugMode) {
  firebaseApp.functions().useEmulator("localhost", 5001);
}

amplitude.getInstance().init(isDebugMode
  ? "406947f8b8fa234111fbb10dfb42ce82"
  : "3a64387ecd01360c0cc845a6c47fbc04");

export const AppContext = createContext();

export default function App() {
  amplitude.getInstance().logEvent("MainPageShow");

  return (
    <AppContext.Provider value={{ firebase: firebaseApp, useReducer }}>
      <ToastContainer
        position='top-center'
        hideProgressBar
        autoClose={2000}
        className={styles.toastContainer}
        toastClassName={styles.toast}
      />
      <Router>
        <Switch>
          <Route path="/v/:uid">
            <Route render={(props) => <VideoContainer {...props} />} />
          </Route>
          <Route path="/:uidOrSlug">
            <Route render={(props) => <HomeContainer {...props} />} />
          </Route>
          <Route path="*">
            <BackPrint>
              <p className='title'>404</p>
              <p className='message'>Page Not Found</p>
            </BackPrint>
          </Route>
        </Switch>
      </Router>
    </AppContext.Provider>
  );
}