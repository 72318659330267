/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */
import styles from "./styles.module.css";

import React from "react";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";

const FormSelectField = ({ className, label, placeholder, error, options, ...rest }) => (
  <div className={[styles.formSelectField, className].join(" ")}>
    <div className={styles.header}>
      <span className={styles.label}>{label}</span>
      <span className={styles.error}>{error}</span>
    </div>
    <Form.Control
      className={styles.control}
      as='select'
      custom
      required
      {...rest}
    >
      {placeholder ? <option value="" disabled defaultValue>{placeholder}</option> : null}
      {options.map((o, index) => <option key={index} value={o.value}>{o.name}</option>)}
    </Form.Control>
    <svg className={styles.arrowIcon} xmlns="http://www.w3.org/2000/svg" width="16.828" height="9.414" viewBox="0 0 16.828 9.414">
      <path d="M14,0,7,7,0,0" transform="translate(1.414 1.414)" fill="none" stroke="#202020" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="2" opacity="0.6" />
    </svg>
  </div>
);

FormSelectField.defaultProps = {
  disabled: false,
};
FormSelectField.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  options: PropTypes.array.isRequired,
};

export default FormSelectField;