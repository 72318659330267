/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */
import styles from "./styles.module.css";

import React from "react";
import PropTypes from "prop-types";
import  _ from "lodash";

const Button = ({ className, text, icon, tail, disabled, loading, ...rest }) => {
  const classes = [styles.button, className];
  if (disabled)
    classes.push(styles.disabled);
  if (loading)
    classes.push(styles.loading);
  return (
    <div className={classes.join(" ")} {...rest}>
      {icon}
      {_.isEmpty(text) ? undefined :
        <span className={styles.text}>{text}</span>}
      {tail}
    </div>
  );
};

Button.defaultProps = {
  disabled: false,
  loading: false,
};
Button.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
  icon: PropTypes.node,
  tail: PropTypes.node,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
};

export default Button;