/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */
import styles from "./styles.module.css";

import React from "react";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";

const FormInputField = ({ className, label, value, error, maxLength, onChange, onSubmit, ...rest }) => (
  <div className={[styles.formInputField, className].join(" ")}>
    <div className={styles.header}>
      <span className={styles.label}>{label}</span>
      {error
        ? <span className={styles.error}>{error}</span>
        : maxLength
          ? <span className={styles.counter}>{value.length}/{maxLength}</span>
          : null
      }
    </div>
    <Form.Control
      className={styles.control}
      onKeyPress={event => {
        if (onSubmit && event.key === "Enter") {
          onSubmit();
        }
      }}
      onChange={(event) => {
        if (maxLength && event.target.value.length > maxLength)
          event.target.value = event.target.value.substring(0, maxLength);
        onChange(event);
      }}
      value={value}
      {...rest}
    />
  </div>
);

FormInputField.defaultProps = {
  disabled: false,
  value: "",
};
FormInputField.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  disabled: PropTypes.bool,
  maxLength: PropTypes.number,
};

export default FormInputField;