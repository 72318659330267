/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */

import styles from "./styles.module.css";

import React from "react";
import PropTypes from "prop-types";

const Header = ({ className, buttons }) => (
  <div className={[styles.header, className].join(" ")}>
    <img alt="logo" src="images/header-logo.svg" />
    <div className={styles.buttons}>
      {buttons}
    </div>
  </div>
);
Header.defaultProps = {
};
Header.propTypes = {
  className: PropTypes.string,
  buttons: PropTypes.node,
};

export default Header;