/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */
import styles from "./styles.module.css";

import amplitude from "amplitude-js";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { BackLoadingIndicator, BackPrint } from "components/back";
import { Button } from "components/buttons";
import { AppContext } from "containers";
import { AsyncStateSelector, isFailed, isInProgress, isSuccessful, isUseless } from "utils";

import { SubmitFeedbackRatingAction, loadLocation, submitNegativeReview } from "./actions";
import FeedbackBloc from "./components/FeedbackBloc";
import GoodRatingPopup from "./components/GoodRatingPopup";
import ThanksBloc from "./components/ThanksBloc";
import { reducer } from "./reducer";

const HomeContainer = () => {
  const { uidOrSlug } = useParams();

  const { useReducer } = React.useContext(AppContext);
  const [{
    loadLocationState,
    submitNegativeReviewState,
  }, dispatch] = useReducer(reducer, {
    loadLocationState: {},
    submitNegativeReviewState: {},
  }, {}, "home");

  // state
  const [isGoodRatingPopupShown, showGoodRatingPopup] = useState(false);
  const [isThanksBlocShown, showThanksBloc] = useState(false);

  // callbacks 
  const callSubmitNegativeReview = useCallback((uidOrSlug, data) =>
    dispatch(submitNegativeReview(uidOrSlug, data)));

  // effects
  useEffect(() => {
    if (isUseless(loadLocation)) {
      dispatch(loadLocation(uidOrSlug));
    }
  }, []);
  useEffect(() => {
    if (isFailed(submitNegativeReviewState)) {
      toast.error("Unable to process request, try again later", { autoClose: 5000 });
    } else if (isSuccessful(submitNegativeReviewState)) {
      amplitude.getInstance().logEvent("ThankYouForYourFeedbackPageShow", {
        location: location.name,
        reviewSubmitted: true,
      });
      showThanksBloc(true);
    }
  }, [submitNegativeReviewState.status]);
  useEffect(() => {
    if (isSuccessful(loadLocationState)) {
      if (loadLocationState.value == null) {
        amplitude.getInstance().logEvent("LocationNotFoundPageShow", {
          uidOrSlug,
        });
      } else {
        document.title = loadLocationState.value.name + " - ReviewX Feedback";
        amplitude.getInstance().logEvent("LoadLocationRequestSucceed", {
          location: _.pick(location, "uid", "name", "slug", "improvetags",
            "googleReviewLink", "yelpReviewLink"),
        });
      }
    }
  }, [isSuccessful(loadLocationState)]);

  return (
    <div className={styles.container}>
      {isThanksBlocShown
        ? <ThanksBloc />
        : <AsyncStateSelector
          state={loadLocationState}
          onProgress={() => <BackLoadingIndicator height="100vh" />}
          onFail={(error) => {
            amplitude.getInstance().logEvent("LoadLocationRequestFailed", {
              error,
            });
            return (
              <BackPrint>
                <p className="title">Failed to load form</p>
                <p className="message">{_.get(error, "message", "Unable to process request")}</p>
                <Button
                  text="Try Again"
                  onClick={() => dispatch(loadLocation(uidOrSlug))}
                />
              </BackPrint>
            );
          }}
          onSuccess={(location) => {
            if (!location) {
              return (
                <BackPrint>
                  <p className="title">404</p>
                  <p className="message">Form Not Found</p>
                </BackPrint>
              );
            }
            return (
              <FeedbackBloc
                location={location}
                isSubmitting={isInProgress(submitNegativeReviewState)}
                onRatingSelected={(data) => {
                  dispatch(SubmitFeedbackRatingAction(location.uid, data.rating));
                }}
                onSubmit={(data) => {
                  if (data.rating > 3) {
                    showGoodRatingPopup(true);
                  } else {
                    callSubmitNegativeReview(location.uid, data);
                  }
                }}
              />
            );
          }}
        />
      }
      <Modal
        centered
        backdrop="static"
        show={isGoodRatingPopupShown}
        onHide={() => showGoodRatingPopup(false)}>
        {isGoodRatingPopupShown &&
          <GoodRatingPopup
            location={loadLocationState.value}
            onClose={() => {
              amplitude.getInstance().logEvent("ThankYouForYourFeedbackPageShow", {
                location: location.name,
                reviewSubmitted: false,
              });
              showGoodRatingPopup(false);
              showThanksBloc(true);
            }}
          />}
      </Modal>
    </div>
  );
};

HomeContainer.propTypes = {
  history: PropTypes.any,
};
export default HomeContainer;
