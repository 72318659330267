/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */
import styles from "./styles.module.css";

import React from "react";

import { ReactComponent as Icon } from "./icon.svg";
import { Bloc } from "components/panels";
import PoweredBy from "../PoweredBy";

const ThanksBloc = ({ ...rest }) => {
  return (
    <Bloc className={styles.container} {...rest}>
      <div className={styles.icon}>
        <Icon height={160} width={160} />
      </div>
      <p className={styles.title}>Thank you for your Feedback</p>
      <p className={styles.text}>Your feedback was successfully submitted</p>
      <PoweredBy className={styles.footer}/>
    </Bloc>
  );
};

ThanksBloc.defaultProps = {
};
ThanksBloc.propTypes = {
};

export default ThanksBloc;