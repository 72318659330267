/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */

import _ from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { BackLoadingIndicator, BackPrint } from "components/back";
import { Button } from "components/buttons";
import { AppContext } from "containers";
import { AsyncStateSelector, isFailed, isInProgress, isSuccessful, isUseless } from "utils";
import { LoadLocationAction, LoadVideoCampaignAction, SubmitVideoReview } from "./actions";
import RecodingBloc from "./components/RecodingBloc";
import ThanksBloc from "./components/ThanksBloc";
import UserForm from "./components/UserForm";
import WelcomeBloc from "./components/WelcomeBloc";
import { reducer } from "./reducer";

import styles from "./styles.module.css";

const VideoContainer = () => {
  const { uid } = useParams();

  const { useReducer } = React.useContext(AppContext);
  const [{
    loadVideoCampaignState,
    loadLocationState,
    submitVideoReviewState,
  }, dispatch] = useReducer(reducer, {
    loadVideoCampaignState: {},
    loadLocationState: {},
    submitVideoReviewState: {},
  }, {}, "video");

  // state
  const [isWelcomeBlocShown, showWelcomeBloc] = useState(true);
  const [isThanksBlocShown, showThanksBloc] = useState(false);
  const [userData, setUserData] = useState(null);

  // load campaign and location
  useEffect(() => {
    if (isUseless(loadVideoCampaignState)) {
      dispatch(LoadVideoCampaignAction(uid));
    }
  }, [loadVideoCampaignState.value]);

  useEffect(() => {
    if (loadVideoCampaignState.value && isUseless(LoadLocationAction)) {
      dispatch(LoadLocationAction(loadVideoCampaignState.value.location));
    }
  }, [loadVideoCampaignState.value]);

  // submit
  useEffect(() => {
    if (isFailed(submitVideoReviewState)) {
      toast.error("Unable to process request, try again later", { autoClose: 5000 });
    } else if (isSuccessful(submitVideoReviewState)) {
      showThanksBloc(true);
    }
  }, [submitVideoReviewState]);

  return (
    <div className={styles.container}>
      <div className={styles.logoContainer}>
        <a href="https://www.patient10x.com" target="_blank" rel="noreferrer">
          <img alt="logo" src="/images/patient10x-logo.svg" />
        </a>
      </div>
      {<AsyncStateSelector
        state={loadVideoCampaignState}
        onProgress={() => <BackLoadingIndicator height="100vh" />}
        onFail={(error) => {
          return (
            <BackPrint>
              <p className="title">Failed to load form</p>
              <p className="message">{_.get(error, "message", "Unable to process request")}</p>
              <Button
                text="Try Again"
                onClick={() => dispatch(LoadVideoCampaignAction(uid))}
              />
            </BackPrint>
          );
        }}
        onSuccess={(campaign) => {
          if (!campaign) {
            return (
              <BackPrint>
                <p className="title">404</p>
                <p className="message">Form Not Found</p>
              </BackPrint>
            );
          }
          return (
            <AsyncStateSelector
              state={loadLocationState}
              onProgress={() => <BackLoadingIndicator height="100vh" />}
              onFail={(error) => {
                return (
                  <BackPrint>
                    <p className="title">Failed to load form</p>
                    <p className="message">{_.get(error, "message", "Unable to process request")}</p>
                    <Button
                      text="Try Again"
                      onClick={() => dispatch(LoadLocationAction(campaign.location))}
                    />
                  </BackPrint>
                );
              }}
              onSuccess={(location) => {
                if (!location) {
                  return (
                    <BackPrint>
                      <p className="title">404</p>
                      <p className="message">Form Not Found</p>
                    </BackPrint>
                  );
                }
                if (isWelcomeBlocShown)
                  return <WelcomeBloc
                    locationName={location.name}
                    onNext={() => showWelcomeBloc(false)} />;
                if (isThanksBlocShown)
                  return <ThanksBloc />;
                if (userData == null) {
                  return (
                    <UserForm
                      location={location}
                      onNext={setUserData}
                    />
                  );
                }
                return (
                  <RecodingBloc
                    campaign={campaign}
                    location={location}
                    isSubmitting={isInProgress(submitVideoReviewState)}
                    onCompleted={(records) => {
                      dispatch(SubmitVideoReview(campaign.uid, userData, records));
                    }}
                  />
                );
              }}
            />
          );
        }} />
      }
      <p className={styles.copyright}>© 2024 Patient10x Reviews. All right reserved</p>
    </div>
  );
};

VideoContainer.propTypes = {
  history: PropTypes.any,
};
export default VideoContainer;
