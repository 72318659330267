/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */
import PropTypes from "prop-types";

import { Button } from "components/buttons";
import { Bloc } from "components/panels";
import { ReactComponent as Image } from "./image.svg";

import styles from "./styles.module.css";

const WelcomeBloc = ({ onNext, locationName, ...rest }) => {
  return (
    <Bloc className={styles.container} {...rest}>
      <div className={styles.image}>
        <Image />
      </div>
      <p className={styles.title}>Share Your Experience with {locationName} Through Video Feedback</p>
      <p className={styles.text}>You have the opportunity to record your testimonial video for our medical services. </p>
      <Button
        className={styles.nextButton}
        text="I agree, let's get started."
        onClick={() => {
          onNext();
        }}
      />
    </Bloc>
  );
};

WelcomeBloc.defaultProps = {
};
WelcomeBloc.propTypes = {
  onNext: PropTypes.func.isRequired,
  locationName: PropTypes.string.isRequired,
};

export default WelcomeBloc;