/*
 * @author Oleg Khalidov <brooth@gmail.com>.
 * -----------------------------------------------
 * Freelance software development:
 * Upwork: https://www.upwork.com/freelancers/~01d93e90d5b37c48d2
 */

import styles from "./styles.module.css";

import React from "react";
import PropTypes from "prop-types";
import amplitude from "amplitude-js";

const PoweredBy = ({ className, locationName, ...rest }) => {
  const classes = [styles.container, className];
  return (
    <a href={`https://www.patient10x.com/reviewx?utm_source=appreferral&utm_medium=${locationName}`}
      target="_blank"
      rel="noreferrer"
      className={classes.join(" ")} {...rest}
      onClick={() => {
        amplitude.getInstance().logEvent("PoweredByClick", {
          location: locationName,
        });
      }} >
      Powered by &nbsp;
      <img alt="logo" height={16} src="/images/header-logo.svg"/>
    </a>
  );
};

PoweredBy.defaultProps = {
};
PoweredBy.propTypes = {
  className: PropTypes.string,
  locationName: PropTypes.string,
};

export default PoweredBy;